import React from "react";
import { NavLink } from "react-router-dom";

import "./sideNavbar.scss";

const SideNavbar = () => {
  return (
    <div className="accordion accordion-sm">
      <div className="accordion__item">
        <ul>
          <li>
            <NavLink className="accordion__item--btn" to="/about">
              Home
            </NavLink>
          </li>
          <li>
            <NavLink className="accordion__item--btn" to="/ourQualityPolicy">
              Quality Policy
            </NavLink>
          </li>
          <li>
            <NavLink
              className="accordion__item--btn"
              to="/ourQualityCertificates"
            >
              Quality Certificates
            </NavLink>
          </li>

          <li>
            <NavLink
              className="accordion__item--btn"
              to="/ourQualityComplaintPolicy"
            >
              Complaint Policy
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SideNavbar;
