import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import Logo from "../../../Images/logo.png";
import DarkLogo from "../../../Images/logo1.png";
import MenuIcon from "../../Icon/Menu";
import MenuCloseIcon from "../../Icon/MenuClose";
import MessageIcon from "../../Icon/Message";
import ArrowDown from "../../Icon/ArrowDown";
import DropdownRightIcon from "../../Icon/DropdownRightIcon";
import LoadingOverlay from "react-loading-overlay";
import "./header.scss";
import { getData } from "../../../Utils/utils";
import { isAuthenticated, signout } from "../../../auth";
import axios from "../../../Services/axios";
import Swal from "sweetalert2";

const Header = ({ logoLg, contactBtn, themeWhite, bgLight, content }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedService, setSelectedService] = useState(null);
  const [menuToggle, setMenuToggle] = useState(false),
    [isLoading, setLoading] = React.useState(false),
    [product, setProduct] = useState({}),
    {
      product_categories = [],
      page: productPage = {},
      service: servicePage = {},
      service_categories = [],
      aboutUs: aboutUsPage = {},
    } = product || {},
    menuToggleHandler = () => {
      if (menuToggle) {
        setMenuToggle(!menuToggle);
      } else {
        setMenuToggle(!menuToggle);
      }
    },
    fetchProductsData = async () => {
      setLoading(true);
      const result = await getData("/get/contents/by/category");
      setProduct(result);
      setLoading(false);
    };

  useEffect(() => {
    if (content && content.email) {
      setSelectedService(content);
    }
  }, [content]);
  const [calender, setCalender] = React.useState([]);
  useEffect(() => {
    if (isAuthenticated()) {
      axios
        .get("/rmpt/user/get/calenders")

        .then((response) => {
          let res = JSON.parse(response.data);
          if (res.status === 200) {
            setCalender(res.data);
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: res.message,
              showConfirmButton: true,
              // timer: 1500,
            });
          }
        })
        .catch((error) => {})
        .then(() => {});
    }
  }, []);
  useEffect(() => {
    fetchProductsData();

    if (bgLight) {
      document.body.classList.add("bg--lightGray");
    } else {
      document.body.classList.remove("bg--lightGray");
    }
  }, [bgLight]);

  const sidemenuDropdownHandler = (event) => {
    event.target.classList.toggle("dropdown--active");
  };

  const handleContactClick = () => {
    console.log(location.pathname, "Pathname_check");
    if (location.pathname === "/") {
      const hardcodedSubject = "General Inquiry";
      const hardcodedEmail = "Info@RMRMs.com";
      return { state: { email: hardcodedEmail, subject: hardcodedSubject } };
    } else if (location.pathname === "/products") {
      const hardcodedSubject = "Products Inquiry";
      const hardcodedEmail = "Sales@RMRMs.com";
      return { state: { email: hardcodedEmail, subject: hardcodedSubject } };
    } else if (location.pathname === "/services") {
      const hardcodedSubject = "Services Inquiry";
      const hardcodedEmail = "Info@RMRMs.com";
      return { state: { email: hardcodedEmail, subject: hardcodedSubject } };
    } else if (location.pathname === "/products/contents/") {
      const hardcodedSubject = selectedService?.subject;
      const hardcodedEmail = selectedService?.email;
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          fromproduct: true,
        },
      };
    } else if (location.pathname === "/service/contents") {
      const hardcodedSubject = selectedService?.subject;
      const hardcodedEmail = selectedService?.email;
      return {
        state: {
          email: hardcodedEmail,
          subject: hardcodedSubject,
          fromservice: true,
        },
      };
    } else if (location.pathname === "/certificates") {
      const hardcodedSubject = "Certificate/Product Information Sheet Inquiry";
      const hardcodedEmail = "Certs@RMRMs.com";
      return { state: { email: hardcodedEmail, subject: hardcodedSubject } };
    } else if (location.pathname === "/industry-catalogs") {
      const hardcodedSubject = "Industry Catalog Inquiry";
      const hardcodedEmail = "Sales@RMRMs.com";
      return { state: { email: hardcodedEmail, subject: hardcodedSubject } };
    } else if (location.pathname === "/about") {
      const hardcodedSubject = "About Us Inquiry";
      const hardcodedEmail = "Info@RMRMs.com";
      return { state: { email: hardcodedEmail, subject: hardcodedSubject } };
    } else if (location.pathname === "/photo-gallery") {
      const hardcodedSubject = "Photo Gallery Inquiry";
      const hardcodedEmail = "Info@RMRMs.com";
      return { state: { email: hardcodedEmail, subject: hardcodedSubject } };
    } else if (location.pathname === "/latest-news") {
      const hardcodedSubject = "Latest News Inquiry";
      const hardcodedEmail = "Info@RMRMs.com";
      return { state: { email: hardcodedEmail, subject: hardcodedSubject } };
    } else if (location.pathname === "/ourQuality") {
      const hardcodedSubject = "Quality Inquiry";
      const hardcodedEmail = "Info@RMRMs.com";
      return { state: { email: hardcodedEmail, subject: hardcodedSubject } };
    } else {
      const hardcodedEmail = "Info@RMRMs.com";
      return { state: { email: hardcodedEmail } };
    }
  };

  LoadingOverlay.propTypes = undefined;
  return (
    <LoadingOverlay active={isLoading} spinner text="Loading ...">
      <header className={`mainHeader ${themeWhite ? "themeWhite" : ""}`}>
        <div className="container">
          <div className="navbar">
            {/* Logo */}
            <div className="navbar__branding">
              <NavLink to="/">
                <img
                  className={`navbar__logo ${logoLg ? "navbar__logo--lg" : ""}`}
                  src={themeWhite ? DarkLogo : Logo}
                  alt="Logo | Rocky Mountain Reference Materials, LLC"
                />
              </NavLink>
            </div>
            {/* Navbar Menu */}
            <div
              className={`navbar__menu ${menuToggle && "mobile--menu-active"}`}
            >
              {/* Mobile Menu Close Button */}
              <button
                className="navbar__menu--close"
                onClick={menuToggleHandler}
              >
                <MenuCloseIcon />
              </button>

              {/* Menu */}
              <ul className="navbar__nav nav">
                <li className="nav__item">
                  <NavLink to="/" className="nav__link">
                    Home
                  </NavLink>
                </li>
                <li className="nav__item nav__item--dropdown">
                  <NavLink
                    to="/products"
                    component={Link}
                    className="nav__link"
                    state={{
                      content: productPage,
                      productMenu: product_categories,
                    }}
                  >
                    Products <ArrowDown />
                  </NavLink>
                  <div className="nav__item--dropdown-menu">
                    {product_categories.map((menu, index) => {
                      return (
                        <a
                          key={index}
                          href={() => false}
                          className="sidemenu--dropdown"
                          onClick={sidemenuDropdownHandler}
                        >
                          {" "}
                          {menu.category_name}
                          <DropdownRightIcon />
                          <div className="nav__item--dropdown-menu sidemenu">
                            {menu.contents.map((subMenu, i) => {
                              return (
                                <NavLink
                                  key={i}
                                  to={"/products/contents/"}
                                  component={Link}
                                  state={{
                                    content: subMenu,
                                    productMenu: product_categories,
                                  }}
                                >
                                  {subMenu.page_title}
                                </NavLink>
                              );
                            })}
                          </div>
                        </a>
                      );
                    })}

                    {/* <NavLink
                      to="/products/ausmon-monitors"
                      component={Link}
                      state={{ productMenu: product_categories }}
                    >
                      Ausmon Monitors
                    </NavLink> */}
                  </div>
                </li>
                <li className="nav__item nav__item--dropdown">
                  <NavLink
                    to="/services"
                    component={Link}
                    className="nav__link"
                    state={{
                      content: servicePage,
                      productMenu: service_categories,
                    }}
                  >
                    Services <ArrowDown />
                  </NavLink>
                  <div className="nav__item--dropdown-menu">
                    {service_categories.map((menu, i) => {
                      return (
                        <NavLink
                          key={i}
                          to={"/service/contents"}
                          component={Link}
                          state={{
                            content: menu,
                            productMenu: service_categories,
                          }}
                        >
                          {menu.page_title}
                        </NavLink>
                      );
                    })}
                  </div>
                </li>
                <li className="nav__item">
                  <NavLink to="/certificates" className="nav__link">
                    Certificates
                  </NavLink>
                </li>
                <li className="nav__item">
                  <NavLink to="/industry-catalogs" className="nav__link">
                    Industry Catalogs
                  </NavLink>
                </li>
                <li className="nav__item">
                  <NavLink to="/search-engine" className="nav__link">
                    Search Engine
                  </NavLink>
                </li>
                <li className="nav__item nav__item--dropdown">
                  <NavLink
                    to="/about"
                    className="nav__link"
                    component={Link}
                    state={{ content: aboutUsPage }}
                  >
                    About Us <ArrowDown />
                  </NavLink>
                  <div className="nav__item--dropdown-menu">
                    <NavLink to="/about" component={Link}>
                      Home
                    </NavLink>
                    <NavLink to="/photo-gallery" component={Link}>
                      Photo Gallery
                    </NavLink>
                    <NavLink to="/latest-news" component={Link}>
                      Latest News
                    </NavLink>
                    <NavLink to="/contact" component={Link}>
                      Contact Us
                    </NavLink>
                    <NavLink to="/ourQualityPolicy" component={Link}>
                      Our Quality
                    </NavLink>
                  </div>
                </li>
                {/* <li className='nav__item nav__item--dropdown'>
                                    <NavLink to='/customer-portal' className='nav__link'>Customer Portal <ArrowDown /></NavLink>
                                    <div className='nav__item--dropdown-menu'>
                                        <NavLink to='/rmpt'>RMPT</NavLink>
                                        <NavLink to='/rmcp'>RMCP</NavLink>
                                        <NavLink to='/customer-login'>Login</NavLink>
                                        <NavLink to='/info-request'>Register</NavLink>
                                    </div>
                                </li> */}
                <li className="nav__item nav__item--dropdown">
                  <a href={() => false} className="nav__link">
                    Customer Portal <ArrowDown />
                  </a>

                  <div className="nav__item--dropdown-menu">
                    <a
                      href={() => false}
                      className="sidemenu--dropdown"
                      onClick={sidemenuDropdownHandler}
                    >
                      RMPT
                      <DropdownRightIcon />
                      <div className="nav__item--dropdown-menu sidemenu">
                        <NavLink to="/customer-login">Login</NavLink>
                        <NavLink to="/info-request">Register</NavLink>
                      </div>
                    </a>
                    <a
                      href={() => false}
                      className="sidemenu--dropdown"
                      onClick={sidemenuDropdownHandler}
                    >
                      RMCP
                      <DropdownRightIcon />
                      <div className="nav__item--dropdown-menu sidemenu">
                        <NavLink to="">Login</NavLink>
                        <NavLink to="">Register</NavLink>
                      </div>
                    </a>
                  </div>
                </li>
              </ul>

              {/* Mobile Call Button */}
              <div className="navbar__menu--btn sm--screen">
                {isAuthenticated() ? (
                  <div className="nav__item nav__item--dropdown">
                    <a href={() => false} className="nav__link">
                      <div className="profile">
                        <div className="profile__image">D</div>
                      </div>
                      {localStorage.getItem("firstname") &&
                        localStorage.getItem("firstname")}{" "}
                      <ArrowDown />
                    </a>

                    <div className="nav__item--dropdown-menu">
                      {localStorage.getItem("usertype") &&
                      localStorage.getItem("usertype") === "analyst" ? (
                        <a
                          onClick={() => {
                            navigate("/my-profile-analyst");
                          }}
                          href={() => false}
                          className="sidemenu--dropdown"
                        >
                          My Profile
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            navigate("/my-profile");
                          }}
                          href={() => false}
                          className="sidemenu--dropdown"
                        >
                          My Profile
                        </a>
                      )}
                      {calender.length === 0 ? (
                        <a
                          onClick={() => {
                            navigate("/sample-calender");
                          }}
                          href={() => false}
                          className="sidemenu--dropdown"
                        >
                          Calendar
                        </a>
                      ) : null}

                      {localStorage.getItem("usertype") &&
                      localStorage.getItem("usertype") !== "analyst" ? (
                        <a
                          onClick={() => {
                            navigate("/profile-data-sheet");
                          }}
                          href={() => false}
                          className="sidemenu--dropdown"
                        >
                          Profile Data Sheet
                        </a>
                      ) : null}
                      {localStorage.getItem("usertype") &&
                      localStorage.getItem("usertype") !== "analyst" ? (
                        <a
                          onClick={() => {
                            navigate("/form-status");
                          }}
                          href={() => false}
                          className="sidemenu--dropdown"
                        >
                          Client Manager Form Status
                        </a>
                      ) : null}
                      {localStorage.getItem("otherusertype") &&
                      localStorage.getItem("otherusertype") === "analyst" ? (
                        <a
                          onClick={() => {
                            navigate("/analyst-form-status");
                          }}
                          href={() => false}
                          className="sidemenu--dropdown"
                        >
                          Analyst Form Status
                        </a>
                      ) : null}
                      <a
                        onClick={() => {
                          navigate("/change-password");
                        }}
                        href={() => false}
                        className="sidemenu--dropdown"
                      >
                        Change Password
                      </a>
                      <a
                        onClick={() => {
                          signout(() => {
                            navigate("/customer-login");
                          });
                        }}
                        href={() => false}
                        className="sidemenu--dropdown"
                      >
                        Logout
                      </a>
                    </div>
                  </div>
                ) : null}
                <a
                  href="/latest-news"
                  className="btn btn--primary color--white"
                >
                  <span>What's New</span>
                </a>
              </div>
            </div>

            {/* Menu Hamburger Icon */}
            <div className="navbar__menu--icon">
              <button onClick={menuToggleHandler}>
                <MenuIcon />
              </button>
            </div>

            {/* Backdrop */}
            <div
              className={`backdrop ${menuToggle && "active"}`}
              onClick={menuToggleHandler}
            ></div>
          </div>
        </div>

        {/* Desktop Call Button */}

        <div className="navbar navbar__menu--btn lg--screen ">
          {isAuthenticated() ? (
            <div className="nav__item nav__item--dropdown">
              <a href={() => false} className="nav__link nav__profile--link">
                <div className="profile">
                  <div className="profile__image">
                    {" "}
                    {localStorage.getItem("firstname") &&
                      localStorage.getItem("firstname").charAt(0)}{" "}
                  </div>
                </div>
                {localStorage.getItem("firstname") &&
                  localStorage.getItem("firstname").split(" ")[0]}{" "}
                <ArrowDown />
              </a>

              <div className="nav__item--dropdown-menu">
                {localStorage.getItem("usertype") &&
                localStorage.getItem("usertype") === "analyst" ? (
                  <a
                    onClick={() => {
                      navigate("/my-profile-analyst");
                    }}
                    href={() => false}
                    className="sidemenu--dropdown"
                  >
                    My Profile
                  </a>
                ) : (
                  <a
                    onClick={() => {
                      navigate("/my-profile");
                    }}
                    href={() => false}
                    className="sidemenu--dropdown"
                  >
                    My Profile
                  </a>
                )}
                {calender.length === 0 ? (
                  <a
                    onClick={() => {
                      navigate("/sample-calender");
                    }}
                    href={() => false}
                    className="sidemenu--dropdown"
                  >
                    Calendar
                  </a>
                ) : null}

                {localStorage.getItem("usertype") &&
                localStorage.getItem("usertype") !== "analyst" ? (
                  <a
                    onClick={() => {
                      navigate("/profile-data-sheet");
                    }}
                    href={() => false}
                    className="sidemenu--dropdown"
                  >
                    Profile Data Sheet
                  </a>
                ) : null}
                {localStorage.getItem("usertype") &&
                localStorage.getItem("usertype") !== "analyst" ? (
                  <a
                    onClick={() => {
                      navigate("/form-status");
                    }}
                    href={() => false}
                    className="sidemenu--dropdown"
                  >
                    Client Manager Form Status
                  </a>
                ) : null}
                {localStorage.getItem("otherusertype") &&
                localStorage.getItem("otherusertype") === "analyst" ? (
                  <a
                    onClick={() => {
                      navigate("/analyst-form-status");
                    }}
                    href={() => false}
                    className="sidemenu--dropdown"
                  >
                    Analyst Form Status
                  </a>
                ) : null}
                <a
                  onClick={() => {
                    navigate("/change-password");
                  }}
                  href={() => false}
                  className="sidemenu--dropdown"
                >
                  Change Password
                </a>
                <a
                  onClick={() => {
                    signout(() => {
                      navigate("/customer-login");
                    });
                  }}
                  href={() => false}
                  className="sidemenu--dropdown"
                >
                  Logout
                </a>
              </div>
            </div>
          ) : null}

          <a href="/latest-news" className="btn btn--primary color--white">
            What's New
          </a>
        </div>

        {/* Fixed Contact Us Button */}
        {/* <a
          href="/contact"
          className={`contact__btn btn--primary ${logoLg ? "top" : ""} ${
            contactBtn ? "hide" : ""
          }`}
          onClick={handleContactClick}
        >
          <MessageIcon />
          Contact Us
        </a> */}
        <NavLink
          className={`contact__btn btn--primary ${logoLg ? "top" : ""} ${
            contactBtn ? "hide" : ""
          }`}
          to={"/contact"}
          component={Link}
          state={handleContactClick()}
        >
          <MessageIcon />
          Contact Us
        </NavLink>
      </header>
    </LoadingOverlay>
  );
};

export default Header;
