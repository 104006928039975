import React, { useEffect, useRef, useState } from "react";
import Header from "../../Common/Header/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CircularProgress from "@mui/material/CircularProgress";

import FormControl from "@mui/material/FormControl";

import { Formik, Form, FieldArray } from "formik";
import * as Yup from "yup";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Swal from "sweetalert2";

import axios from "../../../Services/axios";
import Creatable from "react-select/creatable";
import { withAsyncPaginate, AsyncPaginate } from "react-select-async-paginate";
import { Tooltip } from "@mui/material";

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);
const Calendar = () => {
  const adapter = new AdapterDayjs();
  const minDate = adapter.date(new Date());
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const formattedDate = months[month] + " " + day + ", " + year;
    return formattedDate;
  }
  const [comments, setComments] = useState("");

  new Date(new Date().getTime() + 86400000);

  const initialValues = {
    data: [
      {
        method: "",
        analyst: "",
        instrument: "",
        sample_date: formatDate(new Date()),
        sample_type: "",
      },
    ],
    summary: [],
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });

  const loadMethods = async (search, prevOptions) => {
    await sleep(200);
    let newarray = [
      {
        label: "1 - AES",
        value: "1",
      },
      {
        label: "2 - XRF",
        value: "2",
      },
      {
        label: "3 - ICP",
        value: "3",
      },
      {
        label: "4 - Com",
        value: "4",
      },
      {
        label: "5 - IGF",
        value: "5",
      },
      {
        label: "6 - GDS",
        value: "6",
      },

      {
        label: "7 - FAA",
        value: "7",
      },
      {
        label: "8 - GFAA",
        value: "8",
      },

      {
        label: "9 - Other",
        value: "9",
      },
    ];
    let filteredOptions;
    if (!search) {
      filteredOptions = newarray;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = newarray.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  const loadSampleType = async (search, prevOptions) => {
    await sleep(200);
    let newarray = [
      {
        label: "1 - 8 mm Disk Sample",
        value: "1",
      },
      {
        label: "2 - Chip Pack (10 g)",
        value: "2",
      },
      {
        label: "3 - Pin Pack (1 Qtr.)",
        value: "3",
      },
      {
        label: "4 - Chip Sample(20 g)",
        value: "4",
      },
    ];
    let filteredOptions;
    if (!search) {
      filteredOptions = newarray;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = newarray.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };

  // const getLabel = (value) => {
  //   let arr = [
  //     {
  //       label: "1 - 8 mm Disk Sample",
  //       value: "1",
  //     },
  //     {
  //       label: "2 - Chip Pack (10 g)",
  //       value: "2",
  //     },
  //     {
  //       label: "3 - Pin Pack (1 Qtr.)",
  //       value: "3",
  //     },
  //     {
  //       label: "4 - Chip Sample(20 g)",
  //       value: "4",
  //     },
  //   ];

  //   let index = arr.findIndex((x) => x.value === value);
  //   if (index === -1) return "";
  //   else return arr[index].label;
  // };

  const getValue = (value) => {
    let arr = [
      {
        label: "1 - 8 mm Disk Sample",
        value: "1",
      },
      {
        label: "2 - Chip Pack (10 g)",
        value: "2",
      },
      {
        label: "3 - Pin Pack (1 Qtr.)",
        value: "3",
      },
      {
        label: "4 - Chip Sample(20 g)",
        value: "4",
      },
    ];

    let index = arr.findIndex((x) => x.label === value);
    if (index === -1) return "";
    else return arr[index].value;
  };
  const loadMaterials = async (search, prevOptions) => {
    await sleep(200);
    let newarray = [
      { label: "AF", value: "AF" },
      { label: "AW", value: "AW" },
      { label: "CI", value: "CI" },
      { label: "CS", value: "CS" },
      { label: "Co", value: "Co" },
      { label: "Cu", value: "Cu" },
      { label: "HT", value: "HT" },
      { label: "LA", value: "LA" },
      { label: "Mg", value: "Mg" },
      { label: "Ni", value: "Ni" },
      { label: "SS", value: "SS" },
      { label: "TS", value: "TS" },
      { label: "Ti", value: "Ti" },
      { label: "Zn", value: "Zn" },
    ];

    let filteredOptions;
    if (!search) {
      filteredOptions = newarray;
    } else {
      const searchLower = search.toLowerCase();

      filteredOptions = newarray.filter(({ label }) =>
        label.toLowerCase().includes(searchLower)
      );
    }

    const hasMore = filteredOptions.length > prevOptions.length + 10;
    const slicedOptions = filteredOptions.slice(
      prevOptions.length,
      prevOptions.length + 10
    );

    return {
      options: slicedOptions,
      hasMore,
    };
  };
  const arrayHelpersRef = useRef(null);

  const calenderSchema = Yup.object().shape({
    data: Yup.array().of(
      Yup.object().shape({
        sample_date: Yup.string().required("Required"),
        method: Yup.string().required("Required"),
        // instrument: Yup.string().required("Required"),
        material: Yup.string().required("Required"),
        sample_type: Yup.string().required("Required"),
        analyst: Yup.string()
          .email("Invalid email address")
          .required("Required"),

        // Rest of your amenities object properties
      })
    ),
  });
  const uniqueAnalysts = (arr, date) => {
    let newarr = [];
    arr.forEach((x) => {
      if (formatDate(x.sample_date) === formatDate(date)) {
        newarr.push(x.analyst);
      }
    });
    let uniqueObjArray = newarr.filter((v, i, a) => a.indexOf(v) === i);
    return uniqueObjArray.length;
  };
  const uniqueReports = (arr, date) => {
    let newarr = [];
    arr.forEach((x) => {
      if (formatDate(x.sample_date) === formatDate(date)) {
        newarr.push(x.method);
      }
    });
    return newarr.length;
  };
  const uniqueMethods = (arr, date) => {
    let newarr = [];
    arr.forEach((x) => {
      if (formatDate(x.sample_date) === formatDate(date)) {
        newarr.push(x.method);
      }
    });
    let uniqueObjArray = newarr.filter((v, i, a) => a.indexOf(v) === i);
    return uniqueObjArray.length;
  };
  const uniqueMaterial = (arr, date) => {
    let newarr = [];
    arr.forEach((x) => {
      if (formatDate(x.sample_date) === formatDate(date)) {
        newarr.push(x.material);
      }
    });
    let uniqueObjArray = newarr.filter((v, i, a) => a.indexOf(v) === i);
    return uniqueObjArray.length;
  };
  const uniqueSampleType = (arr, date) => {
    let newarr = [];
    arr.forEach((x) => {
      if (formatDate(x.sample_date) === formatDate(date)) {
        newarr.push(x.sample_type);
      }
    });
    let uniqueObjArray = newarr.filter((v, i, a) => a.indexOf(v) === i);
    return uniqueObjArray.length;
  };

  const createTableBody = (values) => {
    let data = new Map();

    for (let obj of values.data) {
      data.set(formatDate(obj.sample_date).toString(), obj);
    }

    let out = [...data.values()];
    let sortedData = out.sort(
      (x, y) => new Date(x.sample_date) - new Date(y.sample_date)
    );
    return (
      <>
        {sortedData && sortedData.length > 0
          ? sortedData.map((value, index) => {
              return (
                <TableRow>
                  <TableCell align="left" style={{ padding: "4px 0" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        inputFormat="MM/DD/YYYY"
                        value={value.sample_date}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            className="table__calendar--date"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </TableCell>

                  <TableCell align="left">
                    {values.data && values.data.length
                      ? uniqueReports(values.data, value.sample_date)
                      : ""}{" "}
                  </TableCell>

                  <TableCell align="left">
                    {values.data && values.data.length
                      ? uniqueAnalysts(values.data, value.sample_date)
                      : ""}
                  </TableCell>

                  <TableCell align="left">
                    {" "}
                    {values.data && values.data.length
                      ? uniqueMethods(values.data, value.sample_date)
                      : ""}
                  </TableCell>

                  <TableCell align="left">
                    {" "}
                    {values.data && values.data.length
                      ? uniqueMaterial(values.data, value.sample_date)
                      : ""}
                  </TableCell>

                  <TableCell align="left">
                    {" "}
                    {values.data && values.data.length
                      ? uniqueSampleType(values.data, value.sample_date)
                      : ""}
                  </TableCell>
                </TableRow>
              );
            })
          : null}
      </>
    );
  };

  const [loader, setLoader] = useState(false);

  return (
    <>
      <Header themeWhite={true} contactBtn={true} bgLight={true} />
      <div className="mainSection mainPortal">
        {loader ? (
          <div className="loader">
            <CircularProgress />
          </div>
        ) : null}
        <section className="page__section">
          <div className="container">
            <div className="form__container form__user">
              <div className="form__steps">
                <div class="form__steps--item completed">
                  <div class="form__steps--item-circle"></div>
                  <div class="form__steps--item-name">Profile Data Sheet</div>
                </div>
                <div class="form__steps--item active">
                  <div class="form__steps--item-circle"></div>
                  <div class="form__steps--item-name">Sample Calendar</div>
                </div>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={calenderSchema}
                onSubmit={(values, { setSubmitting }) => {
                  let data = new Map();

                  for (let obj of values.data) {
                    data.set(formatDate(obj.sample_date).toString(), obj);
                  }

                  let out = [...data.values()];

                  let newSummary = [];
                  out.forEach((e) => {
                    newSummary.push({
                      sample_date: formatDate(e.sample_date),
                      reports: uniqueReports(values.data, e.sample_date),
                      analysts: uniqueAnalysts(values.data, e.sample_date),
                      methods: uniqueMethods(values.data, e.sample_date),
                      materials: uniqueMaterial(values.data, e.sample_date),
                      samples: uniqueSampleType(values.data, e.sample_date),
                    });
                  });
                  newSummary = newSummary.sort(
                    (x, y) => new Date(x.sample_date) - new Date(y.sample_date)
                  );
                  console.log("sdkdks", values.data);

                  setLoader(true);
                  axios
                    .post("/rmpt/user/save/calender", {
                      summary: newSummary,
                      data: values.data,
                      comment: comments,
                      update_status: {
                        quote_requested: new Date(),
                      },
                    })

                    .then((response) => {
                      let res = JSON.parse(response.data);
                      if (res.status === 200) {
                        setSubmitting(false);
                        setLoader(false);
                        Swal.fire({
                          icon: "success",
                          position: "center",

                          title: res.message,
                          showConfirmButton: true,
                        }).then(() => {
                          window.open("/form-status", "_self");
                        });
                      } else {
                        Swal.fire({
                          position: "center",
                          icon: "error",
                          title: res.message,
                          showConfirmButton: true,
                          // timer: 1500,
                        });
                        setLoader(false);
                        setSubmitting(false);
                      }
                    })
                    .catch((error) => {
                      setSubmitting(false);
                      setLoader(false);
                    })
                    .then(() => {
                      setSubmitting(false);
                      setLoader(false);
                    });
                }}
                render={({
                  values,
                  errors,
                  touched,
                  handleReset,
                  handleBlur,
                  handleChange,
                  setFieldValue,
                }) => {
                  return (
                    <Form>
                      <div className="form__calendar">
                        <div>
                          <TableContainer className="form__table">
                            <Table className="table__calendar table__calendar--variableWidth">
                              <TableHead>
                                <TableRow>
                                  <TableCell align="left" padding="none">
                                    Sample Date
                                  </TableCell>
                                  <TableCell align="left" padding="none">
                                    Analyst Email
                                  </TableCell>
                                  <TableCell align="left" padding="none">
                                    Method
                                  </TableCell>
                                  <TableCell align="left" padding="none">
                                    Material
                                  </TableCell>
                                  <TableCell align="left" padding="none">
                                    Sample Type
                                  </TableCell>
                                  <TableCell align="left" padding="none">
                                    Instrument
                                  </TableCell>
                                  <TableCell
                                    align="left"
                                    padding="none"
                                  ></TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <FieldArray
                                  name="data"
                                  render={(arrayHelpers) => {
                                    arrayHelpersRef.current = arrayHelpers;
                                    return (
                                      <>
                                        {console.log("lawda", values.data)}

                                        {values.data.length > 0 &&
                                          values.data.map((friend, index) => (
                                            <TableRow>
                                              <TableCell
                                                align="left"
                                                padding="none"
                                              >
                                                <LocalizationProvider
                                                  dateAdapter={AdapterDayjs}
                                                >
                                                  <DesktopDatePicker
                                                    minDate={
                                                      minDate
                                                      // index === 0
                                                      //   ? adapter.date(
                                                      //       new Date().setDate(
                                                      //         new Date().getDate() +
                                                      //           1
                                                      //       )
                                                      //     )
                                                      //   : adapter.date(
                                                      //       new Date().setDate(
                                                      //         new Date(
                                                      //           formatDate(
                                                      //             values.data[
                                                      //               index - 1
                                                      //             ].sample_date
                                                      //           )
                                                      //         ).getDate() + 29
                                                      //       )
                                                      //     )
                                                    }
                                                    inputFormat="MM/DD/YYYY"
                                                    value={
                                                      values.data &&
                                                      values.data[index] &&
                                                      values.data[index]
                                                        .sample_date
                                                    }
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `data[${index}].sample_date`,
                                                        formatDate(e)
                                                      );
                                                    }}
                                                    renderInput={(params) => (
                                                      <TextField
                                                        {...params}
                                                        fullWidth
                                                        className="table__calendar--date"
                                                      />
                                                    )}
                                                  />
                                                </LocalizationProvider>

                                                {errors.data &&
                                                  errors.data[index] &&
                                                  errors.data[index]
                                                    .sample_date && (
                                                    <div className="form__input--error">
                                                      {
                                                        errors.data[index]
                                                          .sample_date
                                                      }
                                                    </div>
                                                  )}
                                              </TableCell>

                                              <TableCell
                                                align="left"
                                                padding="none"
                                              >
                                                <Box autoComplete="off">
                                                  <Tooltip
                                                    title={
                                                      values.data[index].analyst
                                                        .length < 21
                                                        ? ""
                                                        : values.data[index]
                                                            .analyst
                                                    }
                                                  >
                                                    <TextField
                                                      name={`data[${index}].analyst`}
                                                      onChange={(e) => {
                                                        setFieldValue(
                                                          `data[${index}].analyst`,
                                                          e.target.value
                                                        );
                                                      }}
                                                      onBlur={handleBlur}
                                                      label=""
                                                      variant="outlined"
                                                      size="small"
                                                      fullWidth
                                                      className="input__email"
                                                    />
                                                  </Tooltip>

                                                  {errors.data &&
                                                    errors.data[index] &&
                                                    errors.data[index]
                                                      .analyst && (
                                                      <div className="form__input--error">
                                                        {
                                                          errors.data[index]
                                                            .analyst
                                                        }
                                                      </div>
                                                    )}
                                                </Box>
                                              </TableCell>

                                              <TableCell
                                                align="left"
                                                padding="none"
                                              >
                                                <FormControl
                                                  fullWidth
                                                  size="small"
                                                >
                                                  <CreatableAsyncPaginate
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `data[${index}].method`,
                                                        e.label
                                                      );
                                                      if (
                                                        e.label === "1 - AES" ||
                                                        e.label === "2 - XRF" ||
                                                        e.label === "6 - GDS"
                                                      ) {
                                                        setFieldValue(
                                                          `data[${index}].sample_type`,
                                                          "1 - 8 mm Disk Sample"
                                                        );
                                                      }
                                                      if (
                                                        e.label === "3 - ICP" ||
                                                        e.label === "7 - FAA" ||
                                                        e.label === "8 - GFAA"
                                                      ) {
                                                        setFieldValue(
                                                          `data[${index}].sample_type`,
                                                          "4 - Chip Sample(20 g)"
                                                        );
                                                      }

                                                      if (
                                                        e.label === "4 - Com"
                                                      ) {
                                                        setFieldValue(
                                                          `data[${index}].sample_type`,
                                                          "2 - Chip Pack (10 g)"
                                                        );
                                                      }
                                                      if (
                                                        e.label === "5 - IGF"
                                                      ) {
                                                        setFieldValue(
                                                          `data[${index}].sample_type`,
                                                          "3 - Pin Pack (1 Qtr.)"
                                                        );
                                                      }
                                                    }}
                                                    onBlur={handleBlur}
                                                    loadOptions={loadMethods}
                                                    className="tableDropdown"
                                                  />
                                                </FormControl>
                                                {errors.data &&
                                                  errors.data[index] &&
                                                  errors.data[index].method && (
                                                    <div className="form__input--error">
                                                      {
                                                        errors.data[index]
                                                          .method
                                                      }
                                                    </div>
                                                  )}
                                              </TableCell>

                                              <TableCell
                                                align="left"
                                                padding="none"
                                              >
                                                <FormControl
                                                  fullWidth
                                                  size="small"
                                                >
                                                  <CreatableAsyncPaginate
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `data[${index}].material`,
                                                        e.value
                                                      );
                                                    }}
                                                    onBlur={handleBlur}
                                                    loadOptions={loadMaterials}
                                                  />
                                                </FormControl>
                                                {errors.data &&
                                                  errors.data[index] &&
                                                  errors.data[index]
                                                    .material && (
                                                    <div className="form__input--error">
                                                      {
                                                        errors.data[index]
                                                          .material
                                                      }
                                                    </div>
                                                  )}
                                              </TableCell>
                                              <TableCell
                                                align="left"
                                                padding="none"
                                              >
                                                <FormControl
                                                  fullWidth
                                                  size="small"
                                                >
                                                  <AsyncPaginate
                                                    // formatOptionLabel={
                                                    //   formatOptionLabel
                                                    // }
                                                    value={{
                                                      label:
                                                        values &&
                                                        values.data &&
                                                        values.data[index] &&
                                                        values.data[index]
                                                          .sample_type,
                                                      value: getValue(
                                                        values &&
                                                          values.data &&
                                                          values.data[index] &&
                                                          values.data[index]
                                                            .sample_type
                                                      ),
                                                    }}
                                                    onChange={(e) => {
                                                      setFieldValue(
                                                        `data[${index}].sample_type`,
                                                        e.label
                                                      );
                                                    }}
                                                    onBlur={handleBlur}
                                                    loadOptions={loadSampleType}
                                                  />
                                                </FormControl>
                                                {errors.data &&
                                                  errors.data[index] &&
                                                  errors.data[index]
                                                    .sample_type && (
                                                    <div className="form__input--error">
                                                      {
                                                        errors.data[index]
                                                          .sample_type
                                                      }
                                                    </div>
                                                  )}
                                              </TableCell>

                                              <TableCell
                                                align="left"
                                                padding="none"
                                              >
                                                <Box autoComplete="off">
                                                  <TextField
                                                    label=""
                                                    name={`data[${index}].instrument`}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    variant="outlined"
                                                    size="small"
                                                  />
                                                  {errors.data &&
                                                    errors.data[index] &&
                                                    errors.data[index]
                                                      .instrument && (
                                                      <div className="form__input--error">
                                                        {
                                                          errors.data[index]
                                                            .instrument
                                                        }
                                                      </div>
                                                    )}
                                                </Box>
                                              </TableCell>

                                              <TableCell
                                                align="left"
                                                padding="none"
                                              >
                                                <div className="table__actions">
                                                  <Tooltip
                                                    title="Remove Entry"
                                                    placement="right"
                                                  >
                                                    <button
                                                      onClick={() => {
                                                        arrayHelpers.remove(
                                                          index
                                                        );
                                                      }}
                                                      className="btn btn--primary"
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 448 512"
                                                      >
                                                        <path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z" />
                                                      </svg>
                                                    </button>
                                                  </Tooltip>
                                                </div>
                                              </TableCell>
                                            </TableRow>
                                          ))}
                                      </>
                                    );
                                  }}
                                />
                              </TableBody>
                            </Table>
                          </TableContainer>

                          <div className="table__actions">
                            <Tooltip title="Add Entry" placement="right">
                              <button
                                onClick={() =>
                                  arrayHelpersRef.current.push({
                                    method: "",
                                    analyst: "",
                                    instrument: "",
                                    sample_date: "",
                                    sample_type: "",
                                  })
                                }
                                className="btn btn--primary"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 448 512"
                                >
                                  <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                                </svg>
                              </button>
                            </Tooltip>
                          </div>
                        </div>
                        {errors &&
                        errors.data &&
                        errors.data.length > 0 ? null : (
                          <div>
                            <h4>Summary</h4>
                            <TableContainer className="form__table">
                              <Table className="table__calendar table__summary">
                                <TableHead>
                                  <TableRow>
                                    <TableCell align="left" padding="none">
                                      Date
                                    </TableCell>
                                    <TableCell align="left" padding="none">
                                      Number of Reports
                                    </TableCell>
                                    <TableCell align="left" padding="none">
                                      Number of Analysts
                                    </TableCell>
                                    <TableCell align="left" padding="none">
                                      Number of Methods
                                    </TableCell>
                                    <TableCell align="left" padding="none">
                                      Number of Materials
                                    </TableCell>
                                    <TableCell align="left" padding="none">
                                      Number of Samples
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>{createTableBody(values)}</TableBody>
                              </Table>
                            </TableContainer>
                            <div className="form__group--flex-full mb_0 mt_10">
                              <div className="form__group">
                                <h4>Comments</h4>
                                <textarea
                                  rows={2}
                                  value={comments}
                                  onChange={(e) => {
                                    setComments(e.target.value);
                                  }}
                                  width="100%"
                                  className="form__input hover--none"
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="form__group flex justifyspace--between">
                        <div>
                          <button
                            className="btn btn--gray"
                            style={{ marginRight: "20px" }}
                          >
                            Back
                          </button>
                          <button type="submit" className="btn btn--primary">
                            Request a Quote
                          </button>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Calendar;
